// a component to open a new url in a new window on load
import { useEffect } from 'react';
import { Box } from '@mui/material';
import './redirect.css';

function Redirect() {
    useEffect(() => {

        // Parse query parameters from the URL
        const searchParams = new URLSearchParams(window.location.search);
        const params: { [key: string]: string } = {};

        // Loop through the search params and add them to the params object
        for (const [key, value] of searchParams.entries()) {
            params[key] = value;
        }
        const queryString = Object.keys(params)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        async function getiManageWorkspace() {
            try {
                const response = await fetch(`api/iManangeInformation?${queryString}`, {
                    method: 'GET',
                    headers: headers
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                window.location.href = decodeURIComponent(data);
            }
            catch (e) {
                window.location.href = "https://cloudimanage.com/auth?auto_submit=false&remember_email=false#/login";
            }
        }
        getiManageWorkspace();

    }, []);

    return (
        <div className="redirect-background">
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh" // Set the height to 100% of the viewport height
            > <Box className='redirect-imanage-box'
                display="flex"
                justifyContent="center"
                alignItems="center">
                    <div>
                        <h1>Redirecting to iManage.Please wait....</h1>
                    </div>
                </Box>

            </Box>
        </div>
    );
}
export default Redirect;


