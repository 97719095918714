import React, { Fragment } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import Redirect from './components/Redirect';
import Home from './components/Home';
import ErrorPage from './components/Error';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <ErrorPage />,

  },
  {
    path: '/imanageworkspace',
    element: <Redirect />,
    errorElement: <ErrorPage />,
  },
])


function App() {
  return <RouterProvider router={router} />
}

export default App;
