import { Box } from "@mui/material";

const Home = () => {
    return (<div className="redirect-background">
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
        > <Box className='redirect-imanage-box'
            display="flex"
            justifyContent="center"
            alignItems="center">
                <div>
                    <h1>Please login</h1>
                </div>
            </Box>

        </Box>
    </div>)
}

export default Home;
